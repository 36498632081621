import { API_DATE_TIME_FORMAT } from 'constants/date';

import { useLazyQuery } from '@apollo/client';
import { Dayjs } from 'dayjs';
import {
    COURT_VIDEO_ANALYTICS_QUERY,
    CourtVideoAnalyticsResponseT,
} from 'shared/graphql/query/courtVideoAnalytics/courtVideoAnalyticsQuery';
import { EMPTY_COURTS_VIDEO_ANALYTICS } from 'types/CourtVideoAnalytics';

import decodeDTO from '../../../utils/DTO/decodeDTO';
import { CourtsVideoAnalyticsDTO } from '../DTO/CourtsVideoAnalyticsDTO';

import { CourtsVideoAnalyticsDataT } from './CourtsVideoAnalyticsData';

const useCourtsVideoAnalytics = (): CourtsVideoAnalyticsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CourtVideoAnalyticsResponseT>(
        COURT_VIDEO_ANALYTICS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCourtVideoAnalyticsData = decodeDTO(
        CourtsVideoAnalyticsDTO,
        EMPTY_COURTS_VIDEO_ANALYTICS,
        (courtsVideoAnalytics) => courtsVideoAnalytics,
        {
            courtsVideoAnalytics: data?.courtsVideoAnalytics,
        },
    );

    const fetchCourtsVideoAnalytics = async (date: Dayjs): Promise<void> => {
        try {
            await fetch({
                variables: {
                    where: {
                        datetime_start: {
                            _gte: date.format(API_DATE_TIME_FORMAT),
                            _lt: date.add(1, 'day').format(API_DATE_TIME_FORMAT),
                        },
                    },
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchCourtsVideoAnalytics,
        loading,
        data: decodedCourtVideoAnalyticsData.courtsVideoAnalytics,
    };
};

export default useCourtsVideoAnalytics;

import {
    PROPERTY_CATEGORY,
    PROPERTY_COACH,
    PROPERTY_COURT_TYPE,
    PROPERTY_DAY,
    PROPERTY_GROUP,
} from 'constants/propertyNames';

import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import * as yup from 'yup';
import { array } from 'yup';

export type UpdatePriceRateFormFields = {
    priceSalesRoundIds: string[];
    categoryIds: string[];
    courtTypeIds: string[];
    days: number[];
    timePeriods: string[];
    saleRate: string | number;
    returnRate: string | number;
    minRate: string | number;
    forwardTransferCost: string | number;
    backTransferCost: string | number;
    transferable: boolean | null;
    cancellable: boolean | null;
    returnCurrency: string;
    saleCurrency: string;
    performerReturnIncomeValue: string | number;
    performerSaleIncomeValue: string | number;
    shareOfPaymentFiat: number;
    shareOfPaymentBonus: number;
    shareOfPaymentFiatStatus: boolean;
    shareOfPaymentBonusStatus: boolean;
    coachCategoryIds: string[];
    groupViewIds: string[];
    transferDisabledBeforeHours: string | number;
};

export const UPDATE_PRICE_RATE_DEFAULT_VALUES: UpdatePriceRateFormFields = {
    priceSalesRoundIds: [],
    categoryIds: [],
    courtTypeIds: [],
    days: [],
    timePeriods: [],
    saleRate: '',
    returnRate: '',
    minRate: '',
    forwardTransferCost: '',
    backTransferCost: '',
    transferable: null,
    cancellable: null,
    returnCurrency: '',
    saleCurrency: '',
    performerReturnIncomeValue: '',
    performerSaleIncomeValue: '',
    shareOfPaymentFiat: 50,
    shareOfPaymentBonus: 50,
    shareOfPaymentFiatStatus: false,
    shareOfPaymentBonusStatus: false,
    coachCategoryIds: [],
    groupViewIds: [],
    transferDisabledBeforeHours: '',
};

export enum UPDATE_PRICE_RATE_FIELDS_KEYS {
    priceSalesRoundIds = 'priceSalesRoundIds',
    categoryIds = 'categoryIds',
    courtTypeIds = 'courtTypeIds',
    days = 'days',
    timePeriods = 'timePeriods',
    saleRate = 'saleRate',
    returnRate = 'returnRate',
    minRate = 'minRate',
    forwardTransferCost = 'forwardTransferCost',
    backTransferCost = 'backTransferCost',
    transferable = 'transferable',
    cancellable = 'cancellable',
    returnCurrency = 'returnCurrency',
    saleCurrency = 'saleCurrency',
    performerReturnIncomeValue = 'performerReturnIncomeValue',
    performerSaleIncomeValue = 'performerSaleIncomeValue',
    shareOfPaymentFiat = 'shareOfPaymentFiat',
    shareOfPaymentBonus = 'shareOfPaymentBonus',
    shareOfPaymentFiatStatus = 'shareOfPaymentFiatStatus',
    shareOfPaymentBonusStatus = 'shareOfPaymentBonusStatus',
    coachCategoryIds = 'coachCategoryIds',
    groupViewIds = 'groupViewIds',
    transferDisabledBeforeHours = 'transferDisabledBeforeHours',
}

type UpdatePriceRateLabelDataT = Record<keyof UpdatePriceRateFormFields, string> & {
    performerReturnIncomeRate: string;
    performerReturnIncomeSum: string;
    performerSaleIncomeRate: string;
    performerSaleIncomeSum: string;
    shareOfPaymentBonus: string;
    shareOfPaymentFiat: string;
};

export const UPDATE_PRICE_RATE_LABEL_DATA: UpdatePriceRateLabelDataT = {
    priceSalesRoundIds: 'Раунд продажи',
    categoryIds: PROPERTY_CATEGORY,
    courtTypeIds: PROPERTY_COURT_TYPE,
    days: PROPERTY_DAY,
    timePeriods: 'Временные промежутки',
    saleRate: 'Тариф продажи',
    returnRate: 'Тариф возврата',
    minRate: 'Минимальный тариф',
    forwardTransferCost: 'Перенос вперед',
    backTransferCost: 'Перенос назад',
    transferable: 'Перенос разрешен',
    cancellable: 'Отмена разрешена',
    returnCurrency: 'Валюта возврата',
    saleCurrency: 'Валюта отмены',
    performerReturnIncomeValue: 'При возврате',
    performerSaleIncomeValue: 'При продаже',
    shareOfPaymentFiatStatus: '',
    shareOfPaymentBonusStatus: '',
    coachCategoryIds: PROPERTY_COACH,
    groupViewIds: PROPERTY_GROUP,
    transferDisabledBeforeHours: 'Перенос отключен за N часов',

    shareOfPaymentBonus: 'Доля оплаты, BON',
    shareOfPaymentFiat: 'Доля оплаты,',

    performerReturnIncomeRate: 'Доля при возврате в процентах',
    performerReturnIncomeSum: 'Доля при возврате в фиате',
    performerSaleIncomeRate: 'Доля при продаже в процентах',
    performerSaleIncomeSum: 'Доля при продаже в фиате',
};

const numberOrEmptyStringSchema = yup.lazy((value) => (value === '' ? yup.string() : yup.number().typeError(COMMON_FORM_ERRORS[FormErrorMessages.Required])));

export const UPDATE_PRICE_RATE_VALIDATION_SCHEMA = yup.object().shape({
    priceSalesRoundIds: array().min(1).required(),
    minRate: numberOrEmptyStringSchema,
    returnRate: numberOrEmptyStringSchema,
    saleRate: numberOrEmptyStringSchema,
    forwardTransferCost: numberOrEmptyStringSchema,
    backTransferCost: numberOrEmptyStringSchema,
    transferDisabledBeforeHours: numberOrEmptyStringSchema,
}).required();

import { ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT } from 'constants/notificationMessage';
import { PROPERTY_END, PROPERTY_IS_ACTIVE } from 'constants/propertyNames';

import dayjs, { Dayjs } from 'dayjs';
import { date, object } from 'yup';

export type UpdateMemberCardFormFields = {
    datetimeEnd: Dayjs;
    isActive: boolean;
};

export const UPDATE_MEMBER_CARD_DEFAULT_VALUES: UpdateMemberCardFormFields = {
    datetimeEnd: dayjs(),
    isActive: false,
};

export enum UPDATE_MEMBER_CARD_FIELDS_KEYS {
    datetimeEnd = 'datetimeEnd',
    isActive = 'isActive',
}

export const UPDATE_MEMBER_CARD_LABEL_DATA = {
    datetimeEnd: PROPERTY_END,
    isActive: PROPERTY_IS_ACTIVE,
};

export const getUpdateMemberCardValidationSchema = (dateTimeStart: Date) => (
    object().shape({
        datetimeEnd: date()
            .min(
                dateTimeStart,
                ERROR_MEMBER_CARD_EXP_DATE_EARLIER_THAN_CURRENT,
            )
            .required(),
    }).required()
);

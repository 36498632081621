import { useMemo } from 'react';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { GROUP_VIEW_HEADER_DATA } from './HeaderData';

export const prepareGroupHeaderData = (groupCategories: FilterOptionT[]) =>
    useMemo(() => mapFields(GROUP_VIEW_HEADER_DATA, {
        groupCategory: groupCategories,
    }), [groupCategories]);

import { useEffect } from 'react';
import useMemberCard from 'shared/data-hook/memberCard/fetch/fetchOne/useMemberCard';
import { useStore } from 'store';

const useFetchMemberCard = (id: string) => {
    const { fetchMemberCard, loading } = useMemberCard();
    const { setMemberCard } = useStore('memberCard');
    const { isOpen } = useStore('updateMemberCardModal');

    useEffect(() => {
        if (isOpen && id) fetchMemberCard(id).then((memberCard) => setMemberCard(memberCard));
    }, [isOpen]);

    return {
        loading,
    };
};

export default useFetchMemberCard;

import { DATE_FORMAT_BASIC } from 'constants/date';
import {
    PROPERTY_CLIENT,
    PROPERTY_MEMBER_CARD_TYPE,
    PROPERTY_START,
} from 'constants/propertyNames';

import { FC, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import DatePicker from 'shared/component/Form/DatePicker';
import Switch from 'shared/component/Form/Switch';
import { MemberCardT } from 'types/MemberCard';

import {
    UPDATE_MEMBER_CARD_FIELDS_KEYS,
    UPDATE_MEMBER_CARD_LABEL_DATA,
    UpdateMemberCardFormFields,
} from './updateFormData';

type PropsT = {
    control: Control<UpdateMemberCardFormFields>;
    setValue: UseFormSetValue<UpdateMemberCardFormFields>;
    memberCard: MemberCardT;
    isOpen: boolean;
};

const UpdateMemberCardForm: FC<PropsT> = observer((props) => {
    const {
        control, setValue, memberCard, isOpen,
    } = props;
    const {
        title, datetimeEnd, datetimeStart, client: { lastName, firstName, middleName },
    } = memberCard;

    useEffect(() => {
        setValue('isActive', memberCard.isActive);
    }, [isOpen, memberCard]);

    return (
        <Grid2 container direction="column" spacing={ 2 }>
            <Grid2>
                <TextField
                    fullWidth
                    label={ PROPERTY_MEMBER_CARD_TYPE }
                    value={ title }
                    size="small"
                    inputProps={ {
                        readOnly: true,
                    } }
                />
            </Grid2>
            <Grid2>
                <TextField
                    fullWidth
                    label={ PROPERTY_CLIENT }
                    value={ `${lastName ?? ''} ${firstName ?? ''} ${middleName ?? ''}` }
                    size="small"
                    inputProps={ {
                        readOnly: true,
                    } }
                />
            </Grid2>
            <Grid2 direction="row" container>
                <Grid2 xs={ 4 }>
                    <TextField
                        fullWidth
                        label={ PROPERTY_START }
                        value={ dayjs(datetimeStart).format(DATE_FORMAT_BASIC) }
                        size="small"
                        inputProps={ {
                            readOnly: true,
                        } }
                    />
                </Grid2>
                <Grid2 xs={ 4 }>
                    <DatePicker
                        fullWidth
                        setValue={ setValue }
                        value={ dayjs(datetimeEnd) }
                        control={ control }
                        label={ UPDATE_MEMBER_CARD_LABEL_DATA.datetimeEnd }
                        name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.datetimeEnd }
                    />
                </Grid2>
                <Grid2 xs={ 4 }>
                    <Switch
                        size="small"
                        label={ UPDATE_MEMBER_CARD_LABEL_DATA.isActive }
                        control={ control }
                        name={ UPDATE_MEMBER_CARD_FIELDS_KEYS.isActive }
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    );
});

export default UpdateMemberCardForm;
